import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { decode } from 'html-entities';

import Hero from "../components/hero/Hero";

const FourZeroFour = (props) => {
  let pageContext = props.pageContext;
  const state = useSelector((state) => state);
  const blocks = state.hydrate.siteConfigData.blocks;

  const FourZeroFour = blocks && blocks['hero--404'] ? blocks['hero--404'].component_data : null;

  const metaTitleSuffix = decode(pageContext?.site_config?.seo.meta_title) || "The Cannabist Company"
  const metaTitle = "Page Not Found | " + metaTitleSuffix;

  return (
    <div>
      <Helmet>
        <title>{ `${metaTitle}` }</title>
      </Helmet>
      <Hero {...FourZeroFour} />
    </div>
  );
};

FourZeroFour.displayName = "FourZeroFour";

export default FourZeroFour;
